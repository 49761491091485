import React, { useEffect, useState } from "react"
import Twitter from "../social/twitter"
import Instagram from "../social/instagram"
// import facebook from "../../images/svgs/facebook.svg"
import share from "../../images/svgs/share.svg"
import "twin.macro"
import Icon from "../icon"
import useAbsoluteUrl from "../../hooks/use-absolute-url"

const Social = ({ title, pathname }) => {
  const [supportsShare, setSuppertShare] = useState(false)

  const url = useAbsoluteUrl(pathname)

  useEffect(() => {
    const supported =
      typeof window !== undefined &&
      typeof window.navigator !== undefined &&
      "canShare" in navigator
    setSuppertShare(supported)
  }, [])

  const handleShareButton = async () => {
    try {
      await window.navigator.share({
        url: url,
        title: title,
      })
    } catch (err) {
      // console.error(err)
    }
  }

  return (
    <div tw="mb-20 lg:mb-28">
      <div tw="mb-2 text-lg font-bold md:text-2xl">Seite teilen</div>
      <ul tw="flex items-center gap-2">
        <li>
          {supportsShare && (
            <button
              onClick={handleShareButton}
              tw="block h-12 w-12 cursor-pointer rounded-full p-3 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300"
              type="button"
              aria-label="Seite teilen"
            >
              <Icon svg={share} tw="fill-current" />
            </button>
          )}
          {!supportsShare && (
            <a
              href={`mailto:?to=&subject=${title}&body=${url}`}
              className="share-button"
              aria-label="Seite teilen"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div tw="block h-12 w-12 cursor-pointer rounded-full p-3 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300">
                <Icon svg={share} tw="fill-current" />
              </div>
            </a>
          )}
        </li>
        {/* <li>
          <a
            href="https://twitter.com/BSGleich"
            target="_blank"
            rel="noreferrer noopener"
            tw="block h-14 w-10 rounded-full p-3 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300"
            aria-label="Facebook"
          >
            <Icon svg={facebook} title="facebook" tw="fill-current" />
          </a>
        </li> */}
        <li>
          <Twitter />
        </li>
        <li>
          <Instagram />
        </li>
      </ul>
    </div>
  )
}

Social.propTypes = {}

export default Social
