import { useStaticQuery, graphql } from "gatsby"

const useAbsoluteUrl = pathname => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  return `${site.siteMetadata.siteUrl}${pathname}`
}

export default useAbsoluteUrl
